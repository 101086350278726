import { useStore } from "@state/store";
import { Group, User } from "@state/types";
import { useCallback, useContext, useEffect, useState } from "react";
import { useFirebaseGroupLookup, userFirebaseGetGroupUser } from "./useFirestoreDocFuncs";
import { getCookieValue, stubbedData } from "./helper";
import { AuthContext } from "@auth/authContext";
import { useSaveHoustonOnboarding } from "@state/logicHooks";

export const useGroupHooks = (steps: any) => {
  const { user, setUser } = useStore();
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>(undefined);
  const [groupUsers, setGroupUsers] = useState<User[]>([]);
  const [sortedGroupUsers, setSortedGroupUsers] = useState<User[] | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [sortingMethod, setSortingMethod] = useState("Alphabetically");
  const [runTour, setRunTour] = useState(false);
  const [refreshKey, setRefreshKey] = useState<number>(0);

  const { firebase } = useContext(AuthContext);

  const loadGroupsArray = useCallback(async () => {
    if (loading) return;

    if (!firebase || !user?.email) {
      setError("Firebase or user email not available");
      return;
    }

    try {
      setLoading(true);
      const firebaseGroup = await useFirebaseGroupLookup(user.email);
      console.log("Loaded Groups:", firebaseGroup);

      if (firebaseGroup && firebaseGroup.length > 0) {
        setGroups(firebaseGroup as Group[]);
      } else {
        setGroups([]);
      }
    } catch (err) {
      setError("Failed to load groups");
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [user?.email, firebase, loading]); // Added firebase here

  const loadGroupUsers = useCallback(
    async (name: string) => {
      if (!groups) return;

      try {
        setLoading(true);
        const group = groups.find(item => item.groupName === name);
        if (!group || !group.users) return;

        const groupUsersArray: User[] = [];
        for (const userID of group.users) {
          if (typeof userID === "string") {
            const user = (await userFirebaseGetGroupUser(userID)) as User;
            if (user) {
              groupUsersArray.push(user);
            }
          }
        }

        if (groupUsersArray.length > 0) {
          setGroupUsers(groupUsersArray);
          setSelectedGroup(group.groupName);
        }
      } catch (err) {
        setError("Failed to load group users");
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [groups, refreshKey],
  );

  const sortGroupUsers = useCallback(() => {
    let sortedUsers: User[] = [];
    if (sortingMethod === "Alphabetically") {
      sortedUsers = [...groupUsers].sort((a, b) => a.firstName.localeCompare(b.firstName));
    } else if (sortingMethod === "leastProgress") {
      sortedUsers = [...groupUsers].sort((a, b) => {
        const aComplete = a.completed?.filter(workshop => workshop.workshopCompleted).length || 0;
        const bComplete = b.completed?.filter(workshop => workshop.workshopCompleted).length || 0;
        return aComplete - bComplete;
      });
    } else if (sortingMethod === "mostProgress") {
      sortedUsers = [...groupUsers].sort((a, b) => {
        const aComplete = a.completed?.filter(workshop => workshop.workshopCompleted).length || 0;
        const bComplete = b.completed?.filter(workshop => workshop.workshopCompleted).length || 0;
        return bComplete - aComplete;
      });
    }

    setSortedGroupUsers(sortedUsers);
  }, [groupUsers, sortingMethod]);

  const handleTour = useCallback(() => {
    const hasCookie = getCookieValue("tour-houston");
    if (hasCookie) {
      if (!user?.houstonOnboardingCompleted) {
        confirmHoustonOnboardingCompleted();
      }
      return;
    }

    const houstonOnboardingCompleted = user?.houstonOnboardingCompleted;

    if (!houstonOnboardingCompleted && steps && steps.length > 0) {
      setSortedGroupUsers([stubbedData]);
      setTimeout(() => {
        setRunTour(true);
      }, 2000);
    }
  }, []);

  useEffect(() => {
    if (firebase && groups.length === 0 && !loading) {
      loadGroupsArray();
    }
  }, [firebase, groups.length, loading, loadGroupsArray]);

  useEffect(() => {
    if (groups.length > 0 && groupUsers.length === 0) {
      loadGroupUsers(groups[0].groupName);
    }
  }, [groups, groupUsers.length, loadGroupUsers]);

  useEffect(() => {
    sortGroupUsers();
  }, [groupUsers, sortingMethod, sortGroupUsers]);

  useEffect(() => {
    handleTour();
  }, [handleTour]);

  const refresh = useCallback(() => {
    if (selectedGroup && !loading) {
      setRefreshKey(prevKey => prevKey + 1);
      loadGroupUsers(selectedGroup);
    }
  }, [selectedGroup, loading, loadGroupUsers]);

  const confirmHoustonOnboardingCompleted = () => {
    const userID = user?.uid;
    setUser({ ...user, houstonOnboardingCompleted: true });
    useSaveHoustonOnboarding(userID);
  };

  return {
    groups,
    groupUsers,
    sortedGroupUsers,
    selectedGroup,
    loadGroupUsers,
    loadGroupsArray,
    loading,
    error,
    sortingMethod,
    setSortingMethod,
    runTour,
    setRunTour,
    refresh,
    confirmHoustonOnboardingCompleted,
  };
};
